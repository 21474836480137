.card-span{
  transition: all 0.4s ease;
  border:0;
  .card-span-img{
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

