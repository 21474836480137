/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */

.badge {
  font-weight: $font-weight-medium;
  @include border-radius (0);
  @include font-size($font-size-base);
  padding: .58rem 1.8rem;
}

.badge-circle {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

@include media-breakpoint-up(md) {
  .badge-circle {
    height: 3.4rem;
    width: 3.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    
  }
}

