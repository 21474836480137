// navbars

.border-navbar-gradient{ 
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.890625) 10.94%, #FFFFFF 86.46%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(60px);
}

.backdrop{
  backdrop-filter: blur(4px);
}

